export const styleVars = {
    brown: '#b66e2d',
    lightBrown: '#9d8f66',
    green: '#586a3a',
    green2: '#7c885f',
    green3: '#84946a',
    green4: '#9ea783',
    green5: '#abb48f',
    green6: '#b4bca4',
    green7: '#bdbd99',
    whiteYellow: '#faf8dd',
    lightestGray: '#f1f1f1',
    gray: '#333',
    secondaryFont: "'Playfair Display', serif",
    breakpointTablet: '768px',
    breakpointLarge: '900px',
    breakpointMedium: '899px',
    breakpointSmall: '600px',
    shadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
}
