export const esTheme = {
  lang: "es",
  nav: {
    shop: "Colección",
    about: "Sobre nosotros",
  },
  footer: {
    contact: "Contacta con nosotros",
    dev: "Este sitio web fue diseñado y desarollado por ",
    unsplash:
      "Este sitio incluye imágenes tomados por Martin Sanchez, Jose Barrios, and Cody McLain",
    and: "y",
    fromUnsplash: "subido en Unsplash.",
  },
  home: {
    headerText: "Descubre la magia del fuego maya",
    headerBtnText: "Ver la colección",
    handcrafted: "Velas únicas hechas a mano en Guatemala",
    unique: "¿Qué nos hace únicos?",
    guatemalanOwned: "Empresario guatemalteco",
    burnTime: "Duración de más de 15 horas",
    empowers: "Apoya artesanos locales",
    gift: "Un regalo único y especial",
    learnMore: "Aprende más",
    postcardLeft:
      "Cuando compras una candela, apoyas a artesanos guatemaltecos e inviertes en su crecimiento",
    postcardRight: "También recibes una pieza de arte única",
    shopNow: "Compra ahora",
  },
  notFoundLoading: {
    loading: "Cargando",
    notFound: "Lo sentimos, pero no podemos encontrar lo que estabas buscando.",
    notFoundButton: "Ver la colección",
  },
  about: {
    headerText: "La magia del fuego Maya en tu casa",
    headerPs: [
      "Las velas traen magia ya que son una manifestación del fuego - uno de los cuatro elementos – que ha cautivado a la humanidad desde tiempos primitivos.",
      "Las velas son la expresión más hermosa utilizada en ceremonias y rituales, incluso los más simples, como encender una para un cumpleaños y pedir un deseo antes de apagarla.",
    ],
    overlayH1: "Arte textil guatemalteco",
    overlayPs: [
      "Nuestras velas agregan el simbolismo de los ancestros y tradiciones mayas, ya que están decoradas con algunos de sus patrones y diseños de telas más hermosos. Más allá de sus dimensiones étnicas, los textiles mayas contienen múltiples significados de índole social, económica y política, y lo más importante, su simbolismo cosmológico, es decir, todos aquellos conceptos relacionados con la cosmovisión maya.",
      "Hoy, sin la necesidad de grandes rituales o ceremonias y en la privacidad de nuestro hogar, todavía podemos estar fascinados por el fuego a la luz mágica de las velas.",
    ],
    fusion:
      "Guatevelas representa el fusión de dos formas de mágia y tradición: el fuego y el arte textil",
    ourArtisans: "Nuestros artesanos",
    victor: "Victor, 38 años",
    victorP:
      "Nuestros artesanos provienen de familias fabricantes de artesanías por generaciones. Ambos coinciden en que la cerería al igual que cualquier otra artesanía, conlleva procesos que precisan mucha dedicación y paciencia; a ambos les gusta su trabajo porque requiere atención al detalle y les llena de satisfacción que su producto sea apreciado. Ellos coinciden en que el artesano desarrolla su talento a través de la experiencia, la experimentación y el amor por su trabajo.",
    edwin: "Edwin, 21 años",
    edwinP:
      "La asociación a la que ellos pertenecen, es una asociación de empleados que elaboran diversos productos artesanales, además de la cerería, que cuenta con casi 25 años de experiencia y constantemente experimenta e investiga para mejorar sus productos.  La asociación vende sus productos y reparte utilidades entre sus miembros, otorga préstamos livianos y ofrece una caja de ahorro, entre otros pequeños beneficios. Actualmente analizan la integración de beneficios adicionales para sus miembros.",
    how: "El proceso de fabricación",
    staggeredTitle1: "Diseño y preparación de moldes",
    staggeredPs1: [
      "El primer paso en la elaboración de las velas de forma cilíndrica, es la preparación de los moldes. Para éstos se utilizan tubos de PVC (policloruro de vinilo) de diferente ancho, según el ancho de las velas y se cortan a la medida que tendrá la altura de la vela. Para las velas cuadradas y ovaladas se utilizan moldes de aluminio.",
      "Luego se limpian los moldes minuciosamente y se les aplica aceite vegetal para facilitar el desmolde.",
    ],
    staggeredTitle2: "Preparación de la cera",
    staggeredPs2: [
      "Cuando la vela será de color, al derretir la parafina se le agrega un tinte del color deseado. Así, se llenan los moldes con la parafina derretida y se colocan las mechas.",
      "Finalmente se dejan secar a temperatura ambiente. Después de aproximadamente 8 horas se sacan de los moldes y se procede a la decoración.",
    ],
    staggeredTitle3: "Decoration",
    staggeredPs3: [
      "Finalmente, las velas se decoran con nuestra técnica propia y cada una se sella con una calcomanía que garantiza su autenticidad.",
    ],
    footerH1: "Encuentra la vela perfecta para ti",
    footerBtn: "Ver el colección",
  },
  productPage: {
    clickPattern: "Colores disponibles",
    buyButton: "Compra en TBD",
    buyLinkGT: "Compra por correo, sólamente en Guatemala",
    moreInfo: "Más información",
    mayAlso: "Te podría interesar...",
    social: "Comparte en redes sociales",
    prodSpecsMeasurements: "Este artículo mide",
    prodSpecsWeight: "y pesa",
    in: "en",
    learnMore: "Más información",
    warning: "Advertencias",
    warnings: [
      "Supervisar mientras se quema",
      "Mantener a distancia de objetos flamables",
      "Mantener fuera del alcance de niños y mascotas",
    ],
    burnInstruction: "Instrucciones para utilizar",
    burnInstructions: [
      "Cortar la mecha a 1/4 pulgada antes de encender.",
      "Mantén la candela libre de cualquier material ajeno incluyendo fósforos y sobras de mechas.",
      "Sólo quemar la candela en una superficie nivelada y resistente al fuego.",
      "No quemar la candela durante más de 3 horas seguidas.",
      "Dejar de utilizar cuando quede solamente 1/4 pulgada de cera.",
    ],
    prodDictionary: [
      {
        prodId: 1001,
        title: "El Progreso",
        tagline:
          "Esta alta vela cilíndrica está nombrada por un departamento en el corazón de Guatemala. Es un centro de mesa perfecto.",
        height: "15 cm",
        width: "6.3 cm",
        weight: "0.7 kg",
        price: "12.00",
        buyLink: "https://guatevelas.com",
        prodImages: [
          "https://i.imgur.com/HZicd5d.jpg",
          "https://i.imgur.com/CrNf3jN.jpg",
          "https://i.imgur.com/BsXghSe.jpg",
          "https://i.imgur.com/BteAGRF.jpg",
          "https://i.imgur.com/L3G6c5g.jpg",
        ],
        recommendedProds: [
          {
            name: "Chiquimula",
            imgUrl: "https://i.imgur.com/rwHF3h3.jpg",
            prodId: 1002,
          },
          {
            name: "Santa Rosa",
            imgUrl: "https://i.imgur.com/6zt97UB.jpg",
            prodId: 1003,
          },
          {
            name: "Izabal",
            imgUrl: "https://i.imgur.com/F30CL3V.jpg",
            prodId: 1004,
          },
        ],
        availableColors: [
          {
            color: "Atitlán Blue",
            colorSampleImage: "https://i.imgur.com/g9n1MYL.jpg",
            images: [
              "https://i.imgur.com/HZicd5d.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Palopó Blue",
            colorSampleImage: "https://i.imgur.com/d9D3otL.jpg",
            images: [
              "https://i.imgur.com/L3G6c5g.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Café Cobán",
            colorSampleImage: "https://i.imgur.com/rcMg4Ob.jpg",
            images: [
              "https://i.imgur.com/OjJf01t.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Cancuén",
            colorSampleImage: "https://i.imgur.com/MGaLdLK.jpg",
            images: [
              "https://i.imgur.com/QtUTSBe.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Comalapa",
            colorSampleImage: "https://i.imgur.com/FMFYO8o.jpg",
            images: [
              "https://i.imgur.com/CrNf3jN.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "El Mirador",
            colorSampleImage: "https://i.imgur.com/HJYm7VZ.jpg",
            images: [
              "https://i.imgur.com/R0u6nJ1.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Lanquín",
            colorSampleImage: "https://i.imgur.com/R0Iltan.jpg",
            images: [
              "https://i.imgur.com/aHutYEk.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Xelajú Moon",
            colorSampleImage: "https://i.imgur.com/KJoK21e.jpg",
            images: [
              "https://i.imgur.com/BsXghSe.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Maíz Azul",
            colorSampleImage: "https://i.imgur.com/RoCSwSY.jpg",
            images: [
              "https://i.imgur.com/vl69gPI.jpg",
              "https://m.media-amazon.com/images/I/71b9kos-22L._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/71gSdkhRnJL._AC_SL1500_.jpg",
              "https://m.media-amazon.com/images/I/71OUyZ8oSJL._AC_SX679_.jpg",
            ],
          },
          {
            color: "Nebaj",
            colorSampleImage: "https://i.imgur.com/YQ2sOZQ.jpg",
            images: [
              "https://i.imgur.com/ysTSTfq.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Patzún",
            colorSampleImage: "https://i.imgur.com/FuiHMi4.jpg",
            images: [
              "https://i.imgur.com/BteAGRF.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Patzicía Red",
            colorSampleImage: "https://i.imgur.com/dQTiqsg.jpg",
            images: [
              "https://i.imgur.com/tIS1U56.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Salcajá",
            colorSampleImage: "https://i.imgur.com/Mkfavtc.jpg",
            images: [
              "https://i.imgur.com/QsUPQWg.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Semuc Champey",
            colorSampleImage: "https://i.imgur.com/qojf3Nr.jpg",
            images: [
              "https://i.imgur.com/LGnaHm9.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Tacaná",
            colorSampleImage: "https://i.imgur.com/Ue6ZpDy.jpg",
            images: [
              "https://i.imgur.com/FJxVg3V.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Ipala Soil",
            colorSampleImage: "https://i.imgur.com/4Hlf0JA.jpg",
            images: [
              "https://i.imgur.com/cKDlKUg.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Tikal",
            colorSampleImage: "https://i.imgur.com/JmJEKlD.jpg",
            images: [
              "https://i.imgur.com/VyqjL1K.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Uaxactún",
            colorSampleImage: "https://i.imgur.com/RWJlpou.jpg",
            images: [
              "https://i.imgur.com/vab1pnL.jpg",
              "https://m.media-amazon.com/images/I/61Kyy1-qLEL._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/61acHBld8NL._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/610nZjKyYYL._AC_SX679_.jpg",
            ],
          },
          {
            color: "Zaculeu",
            colorSampleImage: "https://i.imgur.com/Oc7gOBz.jpg",
            images: [
              "https://i.imgur.com/FJyzvrd.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Zunil",
            colorSampleImage: "https://i.imgur.com/CuQBteH.jpg",
            images: [
              "https://i.imgur.com/bP40C81.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
        ],
      },
      {
        prodId: 1002,
        title: "Chiquimula",
        tagline:
          'Nombrado por "La perla del oriente," una ciudad y departamento en el sureste de Guatemala, esta vela de altura media es una joya escondida.',
        height: "16.5 cm",
        width: "7.6 cm",
        weight: "0.7 kg",
        price: "12.00",
        buyLink: "https://guatevelas.com",
        prodImages: [
          "https://i.imgur.com/4GSG8u4.jpg",
          "https://i.imgur.com/rwHF3h3.jpg",
          "https://i.imgur.com/4RtRsXr.jpg",
          "https://i.imgur.com/LPd5JuG.jpg",
          "https://i.imgur.com/tSwlFck.jpg",
        ],
        recommendedProds: [
          {
            name: "Progreso",
            imgUrl: "https://i.imgur.com/CrNf3jN.jpg",
            prodId: 1001,
          },
          {
            name: "Santa Rosa",
            imgUrl: "https://i.imgur.com/6zt97UB.jpg",
            prodId: 1003,
          },
          {
            name: "Izabal",
            imgUrl: "https://i.imgur.com/F30CL3V.jpg",
            prodId: 1004,
          },
        ],
        availableColors: [
          {
            color: "Atitlán Blue",
            colorSampleImage: "https://i.imgur.com/g9n1MYL.jpg",
            images: [
              "https://i.imgur.com/4GSG8u4.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Palopó Blue",
            colorSampleImage: "https://i.imgur.com/d9D3otL.jpg",
            images: [
              "https://i.imgur.com/M1uS0F1.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Café Cobán",
            colorSampleImage: "https://i.imgur.com/rcMg4Ob.jpg",
            images: [
              "https://i.imgur.com/3XU8bGo.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Cancuén",
            colorSampleImage: "https://i.imgur.com/MGaLdLK.jpg",
            images: [
              "https://i.imgur.com/RRI4OqI.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Comalapa",
            colorSampleImage: "https://i.imgur.com/FMFYO8o.jpg",
            images: [
              "https://i.imgur.com/HRyz3Kg.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "El Mirador",
            colorSampleImage: "https://i.imgur.com/HJYm7VZ.jpg",
            images: [
              "https://i.imgur.com/EqMO3k9.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Lanquín",
            colorSampleImage: "https://i.imgur.com/R0Iltan.jpg",
            images: [
              "https://i.imgur.com/2LRT31Y.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Xelajú Moon",
            colorSampleImage: "https://i.imgur.com/KJoK21e.jpg",
            images: [
              "https://i.imgur.com/41GpzQe.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Maíz Azul",
            colorSampleImage: "https://i.imgur.com/RoCSwSY.jpg",
            images: [
              "https://i.imgur.com/qRmBYoh.jpg",
              "https://m.media-amazon.com/images/I/71b9kos-22L._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/71gSdkhRnJL._AC_SL1500_.jpg",
              "https://m.media-amazon.com/images/I/71OUyZ8oSJL._AC_SX679_.jpg",
            ],
          },
          {
            color: "Nebaj",
            colorSampleImage: "https://i.imgur.com/YQ2sOZQ.jpg",
            images: [
              "https://i.imgur.com/rwHF3h3.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Patzún",
            colorSampleImage: "https://i.imgur.com/FuiHMi4.jpg",
            images: [
              "https://i.imgur.com/zkCBs6x.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Patzicía Red",
            colorSampleImage: "https://i.imgur.com/dQTiqsg.jpg",
            images: [
              "https://i.imgur.com/4RtRsXr.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Salcajá",
            colorSampleImage: "https://i.imgur.com/Mkfavtc.jpg",
            images: [
              "https://i.imgur.com/n5DAjgh.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Semuc Champey",
            colorSampleImage: "https://i.imgur.com/qojf3Nr.jpg",
            images: [
              "https://i.imgur.com/LPd5JuG.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Tacaná",
            colorSampleImage: "https://i.imgur.com/Ue6ZpDy.jpg",
            images: [
              "https://i.imgur.com/XKTtoBL.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Ipala Soil",
            colorSampleImage: "https://i.imgur.com/4Hlf0JA.jpg",
            images: [
              "https://i.imgur.com/JGEHKft.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Tikal",
            colorSampleImage: "https://i.imgur.com/JmJEKlD.jpg",
            images: [
              "https://i.imgur.com/Mdi4TuB.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Uaxactún",
            colorSampleImage: "https://i.imgur.com/RWJlpou.jpg",
            images: [
              "https://i.imgur.com/X3Ltjd6.jpg",
              "https://m.media-amazon.com/images/I/61Kyy1-qLEL._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/61acHBld8NL._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/610nZjKyYYL._AC_SX679_.jpg",
            ],
          },
          {
            color: "Zaculeu",
            colorSampleImage: "https://i.imgur.com/Oc7gOBz.jpg",
            images: [
              "https://i.imgur.com/y88H0fi.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Zunil",
            colorSampleImage: "https://i.imgur.com/CuQBteH.jpg",
            images: [
              "https://i.imgur.com/tSwlFck.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
        ],
      },
      {
        prodId: 1003,
        title: "Santa Rosa",
        tagline:
          "Esta vela cilindrica está nombrada por un departamento que tiene muchos ríos u lagos a la orilla del océano.",
        height: "12.7 cm",
        width: "5 cm",
        weight: "0.36 kg",
        price: "6.00",
        buyLink: "https://guatevelas.com",
        prodImages: [
          "https://i.imgur.com/ZDxsvNt.jpg",
          "https://i.imgur.com/m1cvrWJ.jpg",
          "https://i.imgur.com/cIKYfuT.jpg",
          "https://i.imgur.com/6zt97UB.jpg",
          "https://i.imgur.com/NY7PWvi.jpg",
        ],
        recommendedProds: [
          {
            name: "Chiquimula",
            imgUrl: "https://i.imgur.com/rwHF3h3.jpg",
            prodId: 1002,
          },
          {
            name: "Progreso",
            imgUrl: "https://i.imgur.com/CrNf3jN.jpg",
            prodId: 1001,
          },
          {
            name: "Izabal",
            imgUrl: "https://i.imgur.com/F30CL3V.jpg",
            prodId: 1004,
          },
        ],
        availableColors: [
          {
            color: "Atitlán Blue",
            colorSampleImage: "https://i.imgur.com/g9n1MYL.jpg",
            images: [
              "https://i.imgur.com/ZDxsvNt.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Palopó Blue",
            colorSampleImage: "https://i.imgur.com/d9D3otL.jpg",
            images: [
              "https://i.imgur.com/eWkHBBG.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Café Cobán",
            colorSampleImage: "https://i.imgur.com/rcMg4Ob.jpg",
            images: [
              "https://i.imgur.com/OjJf01t.jpg",
              "https://i.imgur.com/m1cvrWJ.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Cancuén",
            colorSampleImage: "https://i.imgur.com/MGaLdLK.jpg",
            images: [
              "https://i.imgur.com/cIKYfuT.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Comalapa",
            colorSampleImage: "https://i.imgur.com/FMFYO8o.jpg",
            images: [
              "https://i.imgur.com/JwQ86a9.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "El Mirador",
            colorSampleImage: "https://i.imgur.com/HJYm7VZ.jpg",
            images: [
              "https://i.imgur.com/9RIeFPE.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Lanquín",
            colorSampleImage: "https://i.imgur.com/R0Iltan.jpg",
            images: [
              "https://i.imgur.com/R90yLKr.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Xelajú Moon",
            colorSampleImage: "https://i.imgur.com/KJoK21e.jpg",
            images: [
              "https://i.imgur.com/9MQjIzP.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Maíz Azul",
            colorSampleImage: "https://i.imgur.com/RoCSwSY.jpg",
            images: [
              "https://i.imgur.com/V3zDgvg.jpg",
              "https://m.media-amazon.com/images/I/71b9kos-22L._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/71gSdkhRnJL._AC_SL1500_.jpg",
              "https://m.media-amazon.com/images/I/71OUyZ8oSJL._AC_SX679_.jpg",
            ],
          },
          {
            color: "Nebaj",
            colorSampleImage: "https://i.imgur.com/YQ2sOZQ.jpg",
            images: [
              "https://i.imgur.com/i2EzBjg.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Patzún",
            colorSampleImage: "https://i.imgur.com/FuiHMi4.jpg",
            images: [
              "https://i.imgur.com/icXTuhD.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Patzicía Red",
            colorSampleImage: "https://i.imgur.com/dQTiqsg.jpg",
            images: [
              "https://i.imgur.com/uMcaTCF.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Salcajá",
            colorSampleImage: "https://i.imgur.com/Mkfavtc.jpg",
            images: [
              "https://i.imgur.com/5WkyWYU.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Semuc Champey",
            colorSampleImage: "https://i.imgur.com/qojf3Nr.jpg",
            images: [
              "https://i.imgur.com/V1YrESn.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Tacaná",
            colorSampleImage: "https://i.imgur.com/Ue6ZpDy.jpg",
            images: [
              "https://i.imgur.com/JVF9QnM.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Ipala Soil",
            colorSampleImage: "https://i.imgur.com/4Hlf0JA.jpg",
            images: [
              "https://i.imgur.com/NY7PWvi.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Tikal",
            colorSampleImage: "https://i.imgur.com/JmJEKlD.jpg",
            images: [
              "https://i.imgur.com/rIdJKRp.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Uaxactún",
            colorSampleImage: "https://i.imgur.com/RWJlpou.jpg",
            images: [
              "https://i.imgur.com/dmx4K5p.jpg",
              "https://m.media-amazon.com/images/I/61Kyy1-qLEL._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/61acHBld8NL._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/610nZjKyYYL._AC_SX679_.jpg",
            ],
          },
          {
            color: "Zaculeu",
            colorSampleImage: "https://i.imgur.com/Oc7gOBz.jpg",
            images: [
              "https://i.imgur.com/kLAt0WO.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Zunil",
            colorSampleImage: "https://i.imgur.com/CuQBteH.jpg",
            images: [
              "https://i.imgur.com/6zt97UB.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
        ],
      },
      {
        prodId: 1004,
        title: "Izabal",
        tagline:
          "Esta vela baja y cylíndrica acentúa cada rincón de tu casa. El nombre viene del departamento costal entre Belize y Honduras.",
        height: "11.4 cm",
        width: "6.3 cm",
        weight: "0.45 kg",
        price: "9.00",
        buyLink: "https://guatevelas.com",
        prodImages: [
          "https://i.imgur.com/ZeW0rUH.jpg",
          "https://i.imgur.com/54xBYBj.jpg",
          "https://i.imgur.com/T1xQIKl.jpg",
          "https://i.imgur.com/F30CL3V.jpg",
          "https://i.imgur.com/1zQXNoZ.jpg",
        ],
        recommendedProds: [
          {
            name: "Chiquimula",
            imgUrl: "https://i.imgur.com/rwHF3h3.jpg",
            prodId: 1002,
          },
          {
            name: "Santa Rosa",
            imgUrl: "https://i.imgur.com/6zt97UB.jpg",
            prodId: 1003,
          },
          {
            name: "Progreso",
            imgUrl: "https://i.imgur.com/CrNf3jN.jpg",
            prodId: 1001,
          },
        ],
        availableColors: [
          {
            color: "Atitlán Blue",
            colorSampleImage: "https://i.imgur.com/g9n1MYL.jpg",
            images: [
              "https://i.imgur.com/ZeW0rUH.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Palopó Blue",
            colorSampleImage: "https://i.imgur.com/d9D3otL.jpg",
            images: [
              "https://i.imgur.com/QGY51Fi.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Café Cobán",
            colorSampleImage: "https://i.imgur.com/rcMg4Ob.jpg",
            images: [
              "https://i.imgur.com/CGoHBU1.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Cancuén",
            colorSampleImage: "https://i.imgur.com/MGaLdLK.jpg",
            images: [
              "https://i.imgur.com/1zQXNoZ.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Comalapa",
            colorSampleImage: "https://i.imgur.com/FMFYO8o.jpg",
            images: [
              "https://i.imgur.com/IXLMYAj.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "El Mirador",
            colorSampleImage: "https://i.imgur.com/HJYm7VZ.jpg",
            images: [
              "https://i.imgur.com/9EXRO6K.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Lanquín",
            colorSampleImage: "https://i.imgur.com/R0Iltan.jpg",
            images: [
              "https://i.imgur.com/6vmMCrT.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Xelajú Moon",
            colorSampleImage: "https://i.imgur.com/KJoK21e.jpg",
            images: [
              "https://i.imgur.com/x9j6ksw.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Maíz Azul",
            colorSampleImage: "https://i.imgur.com/RoCSwSY.jpg",
            images: [
              "https://i.imgur.com/y80gUHG.jpg",
              "https://m.media-amazon.com/images/I/71b9kos-22L._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/71gSdkhRnJL._AC_SL1500_.jpg",
              "https://m.media-amazon.com/images/I/71OUyZ8oSJL._AC_SX679_.jpg",
            ],
          },
          {
            color: "Nebaj",
            colorSampleImage: "https://i.imgur.com/YQ2sOZQ.jpg",
            images: [
              "https://i.imgur.com/54xBYBj.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Patzún",
            colorSampleImage: "https://i.imgur.com/FuiHMi4.jpg",
            images: [
              "https://i.imgur.com/9KPqEfI.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Patzicía Red",
            colorSampleImage: "https://i.imgur.com/dQTiqsg.jpg",
            images: [
              "https://i.imgur.com/T1xQIKl.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Salcajá",
            colorSampleImage: "https://i.imgur.com/Mkfavtc.jpg",
            images: [
              "https://i.imgur.com/RM9Sor2.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Semuc Champey",
            colorSampleImage: "https://i.imgur.com/qojf3Nr.jpg",
            images: [
              "https://i.imgur.com/vhmTTQ8.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Tacaná",
            colorSampleImage: "https://i.imgur.com/Ue6ZpDy.jpg",
            images: [
              "https://i.imgur.com/F30CL3V.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Ipala Soil",
            colorSampleImage: "https://i.imgur.com/4Hlf0JA.jpg",
            images: [
              "https://i.imgur.com/94fxAAX.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Tikal",
            colorSampleImage: "https://i.imgur.com/JmJEKlD.jpg",
            images: [
              "https://i.imgur.com/SLKThwG.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Uaxactún",
            colorSampleImage: "https://i.imgur.com/RWJlpou.jpg",
            images: [
              "https://i.imgur.com/zeHyNxe.jpg",
              "https://m.media-amazon.com/images/I/61Kyy1-qLEL._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/61acHBld8NL._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/610nZjKyYYL._AC_SX679_.jpg",
            ],
          },
          {
            color: "Zaculeu",
            colorSampleImage: "https://i.imgur.com/Oc7gOBz.jpg",
            images: [
              "https://i.imgur.com/NbnEZe3.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Zunil",
            colorSampleImage: "https://i.imgur.com/CuQBteH.jpg",
            images: [
              "https://i.imgur.com/ugODTOx.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
        ],
      },
      {
        prodId: 1005,
        title: "Escuintla",
        tagline:
          "Nombrado por un departamento costal de Guatemala, esta alta vela rectangular es una pieza bonita y llamativa.",
        height: "16.5 cm",
        width: "7.6 cm",
        weight: "0.75 kg",
        price: "12.00",
        buyLink: "https://guatevelas.com",
        prodImages: [
          "https://i.imgur.com/F3bDS98.jpg",
          "https://i.imgur.com/LReGK7P.jpg",
          "https://i.imgur.com/uSLTkDZ.jpg",
          "https://i.imgur.com/E2DKt9W.jpg",
          "https://i.imgur.com/ni6hK3K.jpg",
        ],
        recommendedProds: [
          {
            name: "San Marcos",
            imgUrl: "https://i.imgur.com/RJ9ZkOt.jpg",
            prodId: 1006,
          },
          {
            name: "Sololá",
            imgUrl: "https://i.imgur.com/TolSEJv.jpg",
            prodId: 1008,
          },
          {
            name: "Alta Verapaz",
            imgUrl: "https://i.imgur.com/XHnfnAO.jpg",
            prodId: 1009,
          },
        ],
        availableColors: [
          {
            color: "Atitlán Blue",
            colorSampleImage: "https://i.imgur.com/g9n1MYL.jpg",
            images: [
              "https://i.imgur.com/F3bDS98.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Palopó Blue",
            colorSampleImage: "https://i.imgur.com/d9D3otL.jpg",
            images: [
              "https://i.imgur.com/FhCDWIC.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Café Cobán",
            colorSampleImage: "https://i.imgur.com/rcMg4Ob.jpg",
            images: [
              "https://i.imgur.com/2EZbCBu.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Cancuén",
            colorSampleImage: "https://i.imgur.com/MGaLdLK.jpg",
            images: [
              "https://i.imgur.com/Q7FQYLo.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Comalapa",
            colorSampleImage: "https://i.imgur.com/FMFYO8o.jpg",
            images: [
              "https://i.imgur.com/KTWYHsp.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "El Mirador",
            colorSampleImage: "https://i.imgur.com/HJYm7VZ.jpg",
            images: [
              "https://i.imgur.com/U3FKxYA.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Lanquín",
            colorSampleImage: "https://i.imgur.com/R0Iltan.jpg",
            images: [
              "https://i.imgur.com/dOJ6OFt.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Xelajú Moon",
            colorSampleImage: "https://i.imgur.com/KJoK21e.jpg",
            images: [
              "https://i.imgur.com/Qfhbpdf.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Maíz Azul",
            colorSampleImage: "https://i.imgur.com/RoCSwSY.jpg",
            images: [
              "https://i.imgur.com/VdGouh9.jpg",
              "https://m.media-amazon.com/images/I/71b9kos-22L._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/71gSdkhRnJL._AC_SL1500_.jpg",
              "https://m.media-amazon.com/images/I/71OUyZ8oSJL._AC_SX679_.jpg",
            ],
          },
          {
            color: "Nebaj",
            colorSampleImage: "https://i.imgur.com/YQ2sOZQ.jpg",
            images: [
              "https://i.imgur.com/UebRsdD.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Patzún",
            colorSampleImage: "https://i.imgur.com/FuiHMi4.jpg",
            images: [
              "https://i.imgur.com/jjJkmP0.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Patzicía Red",
            colorSampleImage: "https://i.imgur.com/dQTiqsg.jpg",
            images: [
              "https://i.imgur.com/LbgWHhI.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Salcajá",
            colorSampleImage: "https://i.imgur.com/Mkfavtc.jpg",
            images: [
              "https://i.imgur.com/IB3ug0m.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Semuc Champey",
            colorSampleImage: "https://i.imgur.com/qojf3Nr.jpg",
            images: [
              "https://i.imgur.com/vLvhbrK.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Tacaná",
            colorSampleImage: "https://i.imgur.com/Ue6ZpDy.jpg",
            images: [
              "https://i.imgur.com/uSLTkDZ.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Ipala Soil",
            colorSampleImage: "https://i.imgur.com/4Hlf0JA.jpg",
            images: [
              "https://i.imgur.com/E2DKt9W.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Tikal",
            colorSampleImage: "https://i.imgur.com/JmJEKlD.jpg",
            images: [
              "https://i.imgur.com/4WyHuui.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Uaxactún",
            colorSampleImage: "https://i.imgur.com/RWJlpou.jpg",
            images: [
              "https://i.imgur.com/ni6hK3K.jpg",
              "https://m.media-amazon.com/images/I/61Kyy1-qLEL._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/61acHBld8NL._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/610nZjKyYYL._AC_SX679_.jpg",
            ],
          },
          {
            color: "Zaculeu",
            colorSampleImage: "https://i.imgur.com/Oc7gOBz.jpg",
            images: [
              "https://i.imgur.com/EJWJG0W.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Zunil",
            colorSampleImage: "https://i.imgur.com/CuQBteH.jpg",
            images: [
              "https://i.imgur.com/LReGK7P.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
        ],
      },
      {
        prodId: 1006,
        title: "San Marcos",
        tagline:
          "Esta vela rectangular con altura mediana lleva el nombre de un departamento en el oeste de Guatemala, en la frontera con México y al borde del Oceano Pacífico.",
        height: "11.4 cm",
        width: "6.3 cm",
        weight: "0.45 kg",
        price: "9.00",
        buyLink: "https://guatevelas.com",
        prodImages: [
          "https://i.imgur.com/FJwScEy.jpg",
          "https://i.imgur.com/cAGp2x8.jpg",
          "https://i.imgur.com/jvPlRcN.jpg",
          "https://i.imgur.com/RJ9ZkOt.jpg",
          "https://i.imgur.com/NuUFNEV.jpg",
        ],
        recommendedProds: [
          {
            name: "Escuintla",
            imgUrl: "https://i.imgur.com/uSLTkDZ.jpg",
            prodId: 1005,
          },
          {
            name: "Sololá",
            imgUrl: "https://i.imgur.com/TolSEJv.jpg",
            prodId: 1008,
          },
          {
            name: "Alta Verapaz",
            imgUrl: "https://i.imgur.com/XHnfnAO.jpg",
            prodId: 1009,
          },
        ],
        availableColors: [
          {
            color: "Atitlán Blue",
            colorSampleImage: "https://i.imgur.com/g9n1MYL.jpg",
            images: [
              "https://i.imgur.com/FJwScEy.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Palopó Blue",
            colorSampleImage: "https://i.imgur.com/d9D3otL.jpg",
            images: [
              "https://i.imgur.com/RJ9ZkOt.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Café Cobán",
            colorSampleImage: "https://i.imgur.com/rcMg4Ob.jpg",
            images: [
              "https://i.imgur.com/jvPlRcN.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Cancuén",
            colorSampleImage: "https://i.imgur.com/MGaLdLK.jpg",
            images: [
              "https://i.imgur.com/JJfQ7jF.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Comalapa",
            colorSampleImage: "https://i.imgur.com/FMFYO8o.jpg",
            images: [
              "https://i.imgur.com/hHk5PS5.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "El Mirador",
            colorSampleImage: "https://i.imgur.com/HJYm7VZ.jpg",
            images: [
              "https://i.imgur.com/NuUFNEV.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Lanquín",
            colorSampleImage: "https://i.imgur.com/R0Iltan.jpg",
            images: [
              "https://i.imgur.com/ClX9zLg.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Xelajú Moon",
            colorSampleImage: "https://i.imgur.com/KJoK21e.jpg",
            images: [
              "https://i.imgur.com/nNgQ7mW.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Maíz Azul",
            colorSampleImage: "https://i.imgur.com/RoCSwSY.jpg",
            images: [
              "https://i.imgur.com/xEBX567.jpg",
              "https://m.media-amazon.com/images/I/71b9kos-22L._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/71gSdkhRnJL._AC_SL1500_.jpg",
              "https://m.media-amazon.com/images/I/71OUyZ8oSJL._AC_SX679_.jpg",
            ],
          },
          {
            color: "Nebaj",
            colorSampleImage: "https://i.imgur.com/YQ2sOZQ.jpg",
            images: [
              "https://i.imgur.com/RWApvsX.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Patzún",
            colorSampleImage: "https://i.imgur.com/FuiHMi4.jpg",
            images: [
              "https://i.imgur.com/fbWPNof.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Patzicía Red",
            colorSampleImage: "https://i.imgur.com/dQTiqsg.jpg",
            images: [
              "https://i.imgur.com/BrPHgJa.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Salcajá",
            colorSampleImage: "https://i.imgur.com/Mkfavtc.jpg",
            images: [
              "https://i.imgur.com/A5MoPlL.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Semuc Champey",
            colorSampleImage: "https://i.imgur.com/qojf3Nr.jpg",
            images: [
              "https://i.imgur.com/LIlEn6U.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Tacaná",
            colorSampleImage: "https://i.imgur.com/Ue6ZpDy.jpg",
            images: [
              "https://i.imgur.com/w2YGIG0.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Ipala Soil",
            colorSampleImage: "https://i.imgur.com/4Hlf0JA.jpg",
            images: [
              "https://i.imgur.com/j4emPKN.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Tikal",
            colorSampleImage: "https://i.imgur.com/JmJEKlD.jpg",
            images: [
              "https://i.imgur.com/wKW8EBm.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Uaxactún",
            colorSampleImage: "https://i.imgur.com/RWJlpou.jpg",
            images: [
              "https://i.imgur.com/ugcZF2T.jpg",
              "https://m.media-amazon.com/images/I/61Kyy1-qLEL._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/61acHBld8NL._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/610nZjKyYYL._AC_SX679_.jpg",
            ],
          },
          {
            color: "Zaculeu",
            colorSampleImage: "https://i.imgur.com/Oc7gOBz.jpg",
            images: [
              "https://i.imgur.com/lg1zwN6.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Zunil",
            colorSampleImage: "https://i.imgur.com/CuQBteH.jpg",
            images: [
              "https://i.imgur.com/cAGp2x8.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
        ],
      },
      {
        prodId: 1007,
        title: "Petén",
        tagline:
          "Esta vela tan única lleva el nombre del departamento donde se encuentra la ciudad maya más famosa de Guatemala, Tikal, que fue nombrada Patrimonio de la Humanidad por la UNESCO. Igual que Tikal, esta vela es una joya de Guatemala.",
        height: "10 cm",
        width: "12.7 cm",
        weight: "0.7 kg",
        price: "12.00",
        buyLink: "https://guatevelas.com",
        prodImages: [
          "https://i.imgur.com/4ZuopU6.jpg",
          "https://i.imgur.com/Kx68GoW.jpg",
          "https://i.imgur.com/ReadUaT.jpg",
          "https://i.imgur.com/tD0cF0Q.jpg",
          "https://i.imgur.com/4ZuopU6.jpg",
        ],
        recommendedProds: [
          {
            name: "Sololá",
            imgUrl: "https://i.imgur.com/TolSEJv.jpg",
            prodId: 1008,
          },
          {
            name: "Chiquimula",
            imgUrl: "https://i.imgur.com/rwHF3h3.jpg",
            prodId: 1002,
          },
          {
            name: "Alta Verapaz",
            imgUrl: "https://i.imgur.com/XHnfnAO.jpg",
            prodId: 1009,
          },
        ],
        availableColors: [
          {
            color: "Atitlán Blue",
            colorSampleImage: "https://i.imgur.com/g9n1MYL.jpg",
            images: [
              "https://i.imgur.com/4ZuopU6.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Palopó Blue",
            colorSampleImage: "https://i.imgur.com/d9D3otL.jpg",
            images: [
              "https://i.imgur.com/oXDkujt.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Café Cobán",
            colorSampleImage: "https://i.imgur.com/rcMg4Ob.jpg",
            images: [
              "https://i.imgur.com/Qtox3EE.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Cancuén",
            colorSampleImage: "https://i.imgur.com/MGaLdLK.jpg",
            images: [
              "https://i.imgur.com/ReadUaT.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Comalapa",
            colorSampleImage: "https://i.imgur.com/FMFYO8o.jpg",
            images: [
              "https://i.imgur.com/pHbzn0X.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "El Mirador",
            colorSampleImage: "https://i.imgur.com/HJYm7VZ.jpg",
            images: [
              "https://i.imgur.com/m9mwyJc.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Lanquín",
            colorSampleImage: "https://i.imgur.com/R0Iltan.jpg",
            images: [
              "https://i.imgur.com/EI8uMHY.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Xelajú Moon",
            colorSampleImage: "https://i.imgur.com/KJoK21e.jpg",
            images: [
              "https://i.imgur.com/xw6Fsth.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Maíz Azul",
            colorSampleImage: "https://i.imgur.com/RoCSwSY.jpg",
            images: [
              "https://i.imgur.com/XK7wLZw.jpg",
              "https://m.media-amazon.com/images/I/71b9kos-22L._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/71gSdkhRnJL._AC_SL1500_.jpg",
              "https://m.media-amazon.com/images/I/71OUyZ8oSJL._AC_SX679_.jpg",
            ],
          },
          {
            color: "Nebaj",
            colorSampleImage: "https://i.imgur.com/YQ2sOZQ.jpg",
            images: [
              "https://i.imgur.com/br5FTxo.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Patzún",
            colorSampleImage: "https://i.imgur.com/FuiHMi4.jpg",
            images: [
              "https://i.imgur.com/tD0cF0Q.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Patzicía Red",
            colorSampleImage: "https://i.imgur.com/dQTiqsg.jpg",
            images: [
              "https://i.imgur.com/2Mtm8he.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Salcajá",
            colorSampleImage: "https://i.imgur.com/Mkfavtc.jpg",
            images: [
              "https://i.imgur.com/9cafwxk.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Semuc Champey",
            colorSampleImage: "https://i.imgur.com/qojf3Nr.jpg",
            images: [
              "https://i.imgur.com/jZcLonQ.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Tacaná",
            colorSampleImage: "https://i.imgur.com/Ue6ZpDy.jpg",
            images: [
              "https://i.imgur.com/4Pc1jgC.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Ipala Soil",
            colorSampleImage: "https://i.imgur.com/4Hlf0JA.jpg",
            images: [
              "https://i.imgur.com/grGX4xD.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Tikal",
            colorSampleImage: "https://i.imgur.com/JmJEKlD.jpg",
            images: [
              "https://i.imgur.com/FLf0gIN.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Uaxactún",
            colorSampleImage: "https://i.imgur.com/RWJlpou.jpg",
            images: [
              "https://i.imgur.com/JH6ZKC0.jpg",
              "https://m.media-amazon.com/images/I/61Kyy1-qLEL._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/61acHBld8NL._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/610nZjKyYYL._AC_SX679_.jpg",
            ],
          },
          {
            color: "Zaculeu",
            colorSampleImage: "https://i.imgur.com/Oc7gOBz.jpg",
            images: [
              "https://i.imgur.com/aQr6Mrz.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Zunil",
            colorSampleImage: "https://i.imgur.com/CuQBteH.jpg",
            images: [
              "https://i.imgur.com/Kx68GoW.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
        ],
      },
      {
        prodId: 1008,
        title: "Sololá",
        tagline:
          "Nombrado en honor de la región donde se encuentra el lago de Atitlán, esta baja vela rectangular representa paz y claridad.",
        height: "10 cm",
        width: "10 cm",
        weight: "0.7 kg",
        price: "12.00",
        buyLink: "https://guatevelas.com",
        prodImages: [
          "https://i.imgur.com/Y3E0oU0.jpg",
          "https://i.imgur.com/wW1rHXr.jpg",
          "https://i.imgur.com/TolSEJv.jpg",
          "https://i.imgur.com/6hlQHZu.jpg",
          "https://i.imgur.com/3RpIzSP.jpg",
        ],
        recommendedProds: [
          {
            name: "San Marcos",
            imgUrl: "https://i.imgur.com/RJ9ZkOt.jpg",
            prodId: 1006,
          },
          {
            name: "Escuintla",
            imgUrl: "https://i.imgur.com/uSLTkDZ.jpg",
            prodId: 1005,
          },
          {
            name: "Alta Verapaz",
            imgUrl: "https://i.imgur.com/XHnfnAO.jpg",
            prodId: 1009,
          },
        ],
        availableColors: [
          {
            color: "Atitlán Blue",
            colorSampleImage: "https://i.imgur.com/g9n1MYL.jpg",
            images: [
              "https://i.imgur.com/Y3E0oU0.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Palopó Blue",
            colorSampleImage: "https://i.imgur.com/d9D3otL.jpg",
            images: [
              "https://i.imgur.com/TolSEJv.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Café Cobán",
            colorSampleImage: "https://i.imgur.com/rcMg4Ob.jpg",
            images: [
              "https://i.imgur.com/rijtsth.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Cancuén",
            colorSampleImage: "https://i.imgur.com/MGaLdLK.jpg",
            images: [
              "https://i.imgur.com/SZozaTH.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Comalapa",
            colorSampleImage: "https://i.imgur.com/FMFYO8o.jpg",
            images: [
              "https://i.imgur.com/6hlQHZu.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "El Mirador",
            colorSampleImage: "https://i.imgur.com/HJYm7VZ.jpg",
            images: [
              "https://i.imgur.com/OERuww9.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Lanquín",
            colorSampleImage: "https://i.imgur.com/R0Iltan.jpg",
            images: [
              "https://i.imgur.com/3RpIzSP.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Xelajú Moon",
            colorSampleImage: "https://i.imgur.com/KJoK21e.jpg",
            images: [
              "https://i.imgur.com/8QVHINY.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Maíz Azul",
            colorSampleImage: "https://i.imgur.com/RoCSwSY.jpg",
            images: [
              "https://i.imgur.com/7EN4B5V.jpg",
              "https://m.media-amazon.com/images/I/71b9kos-22L._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/71gSdkhRnJL._AC_SL1500_.jpg",
              "https://m.media-amazon.com/images/I/71OUyZ8oSJL._AC_SX679_.jpg",
            ],
          },
          {
            color: "Nebaj",
            colorSampleImage: "https://i.imgur.com/YQ2sOZQ.jpg",
            images: [
              "https://i.imgur.com/AbDvjye.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Patzún",
            colorSampleImage: "https://i.imgur.com/FuiHMi4.jpg",
            images: [
              "https://i.imgur.com/IagSK8X.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Patzicía Red",
            colorSampleImage: "https://i.imgur.com/dQTiqsg.jpg",
            images: [
              "https://i.imgur.com/1AEwBal.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Salcajá",
            colorSampleImage: "https://i.imgur.com/Mkfavtc.jpg",
            images: [
              "https://i.imgur.com/pSopPxX.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Semuc Champey",
            colorSampleImage: "https://i.imgur.com/qojf3Nr.jpg",
            images: [
              "https://i.imgur.com/sb58uTN.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Tacaná",
            colorSampleImage: "https://i.imgur.com/Ue6ZpDy.jpg",
            images: [
              "https://i.imgur.com/AaW4GtV.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Ipala Soil",
            colorSampleImage: "https://i.imgur.com/4Hlf0JA.jpg",
            images: [
              "https://i.imgur.com/lU4R46P.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Tikal",
            colorSampleImage: "https://i.imgur.com/JmJEKlD.jpg",
            images: [
              "https://i.imgur.com/xi06F1p.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Uaxactún",
            colorSampleImage: "https://i.imgur.com/RWJlpou.jpg",
            images: [
              "https://i.imgur.com/mu6QsCT.jpg",
              "https://m.media-amazon.com/images/I/61Kyy1-qLEL._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/61acHBld8NL._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/610nZjKyYYL._AC_SX679_.jpg",
            ],
          },
          {
            color: "Zaculeu",
            colorSampleImage: "https://i.imgur.com/Oc7gOBz.jpg",
            images: [
              "https://i.imgur.com/qIi1yWK.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Zunil",
            colorSampleImage: "https://i.imgur.com/CuQBteH.jpg",
            images: [
              "https://i.imgur.com/wW1rHXr.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
        ],
      },
      {
        prodId: 1009,
        title: "Alta Verapaz",
        tagline:
          "Con alturas de hasta 9,200 pies (2,800 m), Alta Verapaz, un departamento de Guatemala caracteriza por sus orquídeas salvajes, es la inspiración perfecta para esta alta vela.",
        height: "30.4 cm",
        width: "5 cm",
        weight: "1.13 kg",
        price: "18.00",
        buyLink: "https://guatevelas.com",
        prodImages: [
          "https://i.imgur.com/BAyz3WK.jpg",
          "https://i.imgur.com/8Q5fdyM.jpg",
          "https://i.imgur.com/wybBi9f.jpg",
          "https://i.imgur.com/XHnfnAO.jpg",
          "https://i.imgur.com/aU31GGi.jpg",
        ],
        recommendedProds: [
          {
            name: "San Marcos",
            imgUrl: "https://i.imgur.com/RJ9ZkOt.jpg",
            prodId: 1006,
          },
          {
            name: "Sololá",
            imgUrl: "https://i.imgur.com/TolSEJv.jpg",
            prodId: 1008,
          },
          {
            name: "Escuintla",
            imgUrl: "https://i.imgur.com/uSLTkDZ.jpg",
            prodId: 1005,
          },
        ],
        availableColors: [
          {
            color: "Atitlán Blue",
            colorSampleImage: "https://i.imgur.com/g9n1MYL.jpg",
            images: [
              "https://i.imgur.com/BAyz3WK.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Palopó Blue",
            colorSampleImage: "https://i.imgur.com/d9D3otL.jpg",
            images: [
              "https://i.imgur.com/RCWeC5l.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Café Cobán",
            colorSampleImage: "https://i.imgur.com/rcMg4Ob.jpg",
            images: [
              "https://i.imgur.com/e5MANvd.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Cancuén",
            colorSampleImage: "https://i.imgur.com/MGaLdLK.jpg",
            images: [
              "https://i.imgur.com/8Q5fdyM.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Comalapa",
            colorSampleImage: "https://i.imgur.com/FMFYO8o.jpg",
            images: [
              "https://i.imgur.com/iJGuDyY.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "El Mirador",
            colorSampleImage: "https://i.imgur.com/HJYm7VZ.jpg",
            images: [
              "https://i.imgur.com/eejHtil.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Lanquín",
            colorSampleImage: "https://i.imgur.com/R0Iltan.jpg",
            images: [
              "https://i.imgur.com/zf6TAYE.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Xelajú Moon",
            colorSampleImage: "https://i.imgur.com/KJoK21e.jpg",
            images: [
              "https://i.imgur.com/ZZMTX54.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Maíz Azul",
            colorSampleImage: "https://i.imgur.com/RoCSwSY.jpg",
            images: [
              "https://i.imgur.com/DID5JRH.jpg",
              "https://m.media-amazon.com/images/I/71b9kos-22L._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/71gSdkhRnJL._AC_SL1500_.jpg",
              "https://m.media-amazon.com/images/I/71OUyZ8oSJL._AC_SX679_.jpg",
            ],
          },
          {
            color: "Nebaj",
            colorSampleImage: "https://i.imgur.com/YQ2sOZQ.jpg",
            images: [
              "https://i.imgur.com/yrqJAe6.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Patzún",
            colorSampleImage: "https://i.imgur.com/FuiHMi4.jpg",
            images: [
              "https://i.imgur.com/wybBi9f.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Patzicía Red",
            colorSampleImage: "https://i.imgur.com/dQTiqsg.jpg",
            images: [
              "https://i.imgur.com/BnIhTou.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Salcajá",
            colorSampleImage: "https://i.imgur.com/Mkfavtc.jpg",
            images: [
              "https://i.imgur.com/fMCZKE3.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Semuc Champey",
            colorSampleImage: "https://i.imgur.com/qojf3Nr.jpg",
            images: [
              "https://i.imgur.com/mZL1rdv.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Tacaná",
            colorSampleImage: "https://i.imgur.com/Ue6ZpDy.jpg",
            images: [
              "https://i.imgur.com/2VEpVVr.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Ipala Soil",
            colorSampleImage: "https://i.imgur.com/4Hlf0JA.jpg",
            images: [
              "https://i.imgur.com/XHnfnAO.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Tikal",
            colorSampleImage: "https://i.imgur.com/JmJEKlD.jpg",
            images: [
              "https://i.imgur.com/HD624uz.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Uaxactún",
            colorSampleImage: "https://i.imgur.com/RWJlpou.jpg",
            images: [
              "https://i.imgur.com/ybu432z.jpg",
              "https://m.media-amazon.com/images/I/61Kyy1-qLEL._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/61acHBld8NL._AC_SX679_.jpg",
              "https://m.media-amazon.com/images/I/610nZjKyYYL._AC_SX679_.jpg",
            ],
          },
          {
            color: "Zaculeu",
            colorSampleImage: "https://i.imgur.com/Oc7gOBz.jpg",
            images: [
              "https://i.imgur.com/0trpk9n.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
          {
            color: "Zunil",
            colorSampleImage: "https://i.imgur.com/CuQBteH.jpg",
            images: [
              "https://i.imgur.com/aU31GGi.jpg",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
              "https://via.placeholder.com/300x400/000000",
            ],
          },
        ],
      },
    ],
  },
};
